import { schema } from 'normalizr'

// Schemas
export const market = new schema.Entity('markets')
export const project = new schema.Entity('projects', {
  market: market,
})
export const projectDate = new schema.Entity('projectDate')
export const timeLog = new schema.Entity('timeLog')
export const manufacturer = new schema.Entity('manufacturers')
export const siteRequirement = new schema.Entity('siteRequirements')
export const workerType = new schema.Entity('workerTypes')
export const projectWorker = new schema.Entity('projectWorkers')
export const shiftInvite = new schema.Entity('shiftInvites', {
  projectWorker: projectWorker,
  projectDate: projectDate,
  timeLog: timeLog,
})
export const projectInvite = new schema.Entity('projectInvites', {
  project: project,
  shiftInvites: [shiftInvite],
  waitlistedShiftInvites: [shiftInvite],
})
export const projects = new schema.Object({ projects: new schema.Array(project) })
export const foreman = new schema.Entity('foreman')

export const foremen = new schema.Object({ foremen: new schema.Array(foreman) }) 