import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux';
import createDebounce from 'redux-debounced';
import thunk from 'redux-thunk';
// import { composeWithDevTools } from '@redux-devtools/extension';
import { createWrapper } from 'next-redux-wrapper';
import { getAuthHeaders } from '../lib/session';
import api from '../middlewares/api';
import * as reducers from '../modules';
import { SIGN_OUT_SUCCESS } from '../modules/users';

const getInitialState = () => ({
    config: {
        apiUrl: process.env.NEXT_PUBLIC_API_SERVER,
        googleApiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
    },
});

const appReducer = combineReducers({
    config: (state = {}) => state,
    ...reducers,
});

const rootReducer = (state, action) => {
    if (action.type === SIGN_OUT_SUCCESS) {
        state = { config: state.config };
    }
    return appReducer(state, action);
};

const configureStore = () => {
    const middlewares = [createDebounce(), thunk, api(() => getAuthHeaders())];
    const initialState = getInitialState();
    const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;
    const enhancer = composeEnhancers(applyMiddleware(...middlewares));
    return createStore(rootReducer, initialState, enhancer);
};

const wrapper = createWrapper(configureStore, { debug: false });

export default wrapper;
