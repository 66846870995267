import { CALL_API, HTTP_METHODS } from '../middlewares/api'

// API Actions
const CONNECT_URL_REQUEST = 'steady-install/workers/CONNECT_URL_REQUEST'
const CONNECT_URL_SUCCESS = 'steady-install/workers/CONNECT_URL_SUCCESS'
const CONNECT_URL_FAILURE = 'steady-install/workers/CONNECT_URL_FAILURE'

const CONNECT_FINISH_REQUEST = 'steady-install/workers/CONNECT_FINISH_REQUEST'
const CONNECT_FINISH_SUCCESS = 'steady-install/workers/CONNECT_FINISH_SUCCESS'
const CONNECT_FINISH_FAILURE = 'steady-install/workers/CONNECT_FINISH_FAILURE'

const UPDATE_WORKER_REQUEST = 'steady-install/workers/UPDATE_WORKER_REQUEST'
const UPDATE_WORKER_SUCCESS = 'steady-install/workers/UPDATE_WORKER_SUCCESS'
const UPDATE_WORKER_FAILURE = 'steady-install/workers/UPDATE_WORKER_FAILURE'

export const WORKER_EXPERIENCE_ROUTE = "/worker-registration/work-experience";
export const WORKER_AVAILABILITY_ROUTE = '/worker-registration/availability';

// Initial State
const initialState = {
  connectUrlStatus: null,
  connectUrlError: null,
  connectFinishStatus: null,
  connectFinishError: null,
  updateWorkerStatus: null,
  updateWorkerError: null
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case CONNECT_URL_REQUEST:
      return {
        ...state,
        connectUrlStatus: 'requesting',
        connectUrlError: null,
      }
    case CONNECT_URL_SUCCESS:
      return {
        ...state,
        connectUrlStatus: 'success',
        connectUrlError: null,
      }
    case CONNECT_URL_FAILURE:
      return {
        ...state,
        connectUrlStatus: 'failed',
        connectUrlError: action.error,
      }
    case CONNECT_FINISH_REQUEST:
      return {
        ...state,
        connectFinishStatus: 'requesting',
        connectFinishError: null,
      }
    case CONNECT_FINISH_SUCCESS:
      return {
        ...state,
        connectFinishStatus: 'success',
        connectFinishError: null,
      }
    case CONNECT_FINISH_FAILURE:
      return {
        ...state,
        connectFinishStatus: 'failed',
        connectFinishError: action.error,
      }
    case UPDATE_WORKER_REQUEST:
      return {
        ...state,
        updateWorkerStatus: 'requesting',
        updateWorkerError: null,
      }
    case UPDATE_WORKER_SUCCESS:
      return {
        ...state,
        updateWorkerStatus: 'success',
        updateWorkerError: null,
      }
    case UPDATE_WORKER_FAILURE:
      return {
        ...state,
        updateWorkerStatus: 'failed',
        updateWorkerError: action.error,
      }
    default:
      return state
  }
}


// Selectors
export const selectConnectUrlStatus = state => state.workers.connectUrlStatus;
export const selectConnectUrlError = state => state.workers.connectUrlError;
export const selectConnectFinishStatus = state => state.workers.connectFinishStatus;
export const selectConnectFinishError = state => state.workers.connectFinishError;
export const selectUpdateWorkerStatus = state => state.workers.updateWorkerStatus;
export const selectUpdateWorkerError = state => state.workers.updateWorkerError;


// Action Creators
export const getStripeConnectUrl = (workerId) => ({
  [CALL_API]: {
    types: [CONNECT_URL_REQUEST, CONNECT_URL_SUCCESS, CONNECT_URL_FAILURE],
    endpoint: `workers/${workerId}/stripe_connect_url`,
  },
})

export const stripeConnectFinish = (code, state) => ({
  [CALL_API]: {
    types: [CONNECT_FINISH_REQUEST, CONNECT_FINISH_SUCCESS, CONNECT_FINISH_FAILURE],
    endpoint: `workers/${state}/stripe_connect_finish`,
    method: HTTP_METHODS.POST,
    body: {
      code,
      state,
    }
  },
})

export const anonymousStripeConnectFinish = (params) => ({
  [CALL_API]: {
    types: [CONNECT_FINISH_REQUEST, CONNECT_FINISH_SUCCESS, CONNECT_FINISH_FAILURE],
    endpoint: 'workers/stripe_connect_finish',
    method: HTTP_METHODS.POST,
    body: { ...params }
  },
})

export const updateWorker = (workerId, worker) => ({
  [CALL_API]: {
    types: [UPDATE_WORKER_REQUEST, UPDATE_WORKER_SUCCESS, UPDATE_WORKER_FAILURE],
    endpoint: `workers/${workerId}`,
    method: HTTP_METHODS.PUT,
    body: { worker }
  },
})