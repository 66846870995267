import { createSelector } from 'reselect'
import { CALL_API } from '../middlewares/api'
import { market as marketSchema } from '../lib/schema'
import {selectEntities} from "./entities"
import {denormalize} from "normalizr"

// API Actions
const RETRIEVE_MARKETS_REQUEST = 'steady-install/workers/RETRIEVE_MARKETS_REQUEST'
const RETRIEVE_MARKETS_SUCCESS = 'steady-install/workers/RETRIEVE_MARKETS_SUCCESS'
const RETRIEVE_MARKETS_FAILURE = 'steady-install/workers/RETRIEVE_MARKETS_FAILURE'

// Initial State
const initialState = {
  marketIds: null,
  retrieveMarketsStatus: null,
  retrieveMarketsError: null,
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_MARKETS_REQUEST:
      return {
        ...state,
        retrieveMarketsStatus: "requesting",
        retrieveMarketsError: null
      }
    case RETRIEVE_MARKETS_SUCCESS: 
      return {
        ...state,
        retrieveMarketsStatus: "success",
        retrieveMarketsError: null,
        marketIds: action.payload.result
      }
    case RETRIEVE_MARKETS_FAILURE:
      return {
        ...state,
        retrieveMarketsStatus: "failed",
        retrieveMarketsError: action.error
      }
    default:
      return state
  }
}


// Selectors
export const selectMarketIds = state => state.markets.marketIds
export const selectMarkets = createSelector(
  selectMarketIds,
  selectEntities,
  (marketIds, entities) => denormalize(marketIds, [marketSchema], entities)
)
export const selectRetrieveMarketsStatus = state => state.markets.retrieveMarketsStatus
export const selectRetrieveMarketsError = state => state.markets.retrieveMarketsError
// Action Creators
export const retrieveMarkets = () => ({
  [CALL_API]: {
    types: [RETRIEVE_MARKETS_REQUEST, RETRIEVE_MARKETS_SUCCESS, RETRIEVE_MARKETS_FAILURE],
    endpoint: `markets`,
    schema: [marketSchema]

  },
})