import { createSelector } from 'reselect'
import { CALL_API, HTTP_METHODS } from '../middlewares/api'
import { denormalize } from 'normalizr'
import { selectEntities } from './entities'
import { projectInvite as projectInviteSchema} from '../lib/schema'


// API Actions
const PROJECT_INVITE_REQUEST = 'steady-install/projectInvites/PROJECT_INVITE_REQUEST'
const PROJECT_INVITE_SUCCESS = 'steady-install/projectInvites/PROJECT_INVITE_SUCCESS'
const PROJECT_INVITE_FAILURE = 'steady-install/projectInvites/PROJECT_INVITE_FAILURE'

const UPDATE_PROJECT_INVITE_REQUEST = 'steady-install/projectInvites/UPDATE_PROJECT_INVITE_REQUEST'
const UPDATE_PROJECT_INVITE_SUCCESS = 'steady-install/projectInvites/UPDATE_PROJECT_INVITE_SUCCESS'
const UPDATE_PROJECT_INVITE_FAILURE = 'steady-install/projectInvites/UPDATE_PROJECT_INVITE_FAILURE'

const PROJECT_INVITES_REQUEST = 'steady-install/projectInvites/PROJECT_INVITES_REQUEST'
const PROJECT_INVITES_SUCCESS = 'steady-install/projectInvites/PROJECT_INVITES_SUCCESS'
const PROJECT_INVITES_FAILURE = 'steady-install/projectInvites/PROJECT_INVITES_FAILURE'


// Initial State
const initialState = {
  projectInviteId: null,
  projectInviteIds: null,
  retrieveProjectInvitesStatus: null,
  retrieveProjectInviteStatus: null,
  updateProjectInviteStatus: null,
  retrieveProjectInviteError: null,
  retrieveProjectInvitesError: null,
  updateProjectInviteError: null,
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_INVITE_REQUEST:
      return {
        ...state,
        retrieveProjectInviteStatus: "requesting",
        retrieveProjectInviteError: null,
      }
    case PROJECT_INVITE_SUCCESS:
      return {
        ...state,
        projectInviteId: action.payload.result,
        retrieveProjectInviteStatus: "success",
        retrieveProjectInviteError: null,
      }
    case PROJECT_INVITE_FAILURE:
      return {
        ...state,
        projectInviteId: null,
        retrieveProjectInviteStatus: "failed",
        retrieveProjectInviteError: action.error,
      }
    case PROJECT_INVITES_REQUEST:
      return {
        ...state,
        retrieveProjectInvitesStatus: "requesting",
        retrieveProjectInvitesError: null,
      }
    case PROJECT_INVITES_SUCCESS:
      return {
        ...state,
        projectInviteIds: action.payload.result,
        retrieveProjectInvitesStatus: "success",
        retrieveProjectInvitesError: null,
      }
    case PROJECT_INVITES_FAILURE:
      return {
        ...state,
        projectInviteIds: null,
        retrieveProjectInvitesStatus: "failed",
        retrieveProjectInvitesError: action.error,
      }
    case UPDATE_PROJECT_INVITE_REQUEST:
      return {
        ...state,
        updateProjectInviteStatus: "requesting",
        updateProjectInviteError: null,
      }
    case UPDATE_PROJECT_INVITE_SUCCESS:
      return {
        ...state,
        projectInviteId: action.payload.result,
        updateProjectInviteStatus: "success",
        updateProjectInviteError: null,
      }
    case UPDATE_PROJECT_INVITE_FAILURE:
      return {
        ...state,
        projectInviteId: null,
        updateProjectInviteStatus: "failed",
        updateProjectInviteError: action.error,
      }
    default:
      return state
  }
}


// Selectors
export const selectProjectInviteId = state => state.projectInvites.projectInviteId
export const selectProjectInvite = createSelector(
  selectProjectInviteId,
  selectEntities,
  (projectInviteId, entities) => denormalize(projectInviteId, projectInviteSchema, entities)
)

export const selectProjectInviteIds = state => state.projectInvites.projectInviteIds
export const selectProjectInvites = createSelector(
  selectProjectInviteIds,
  selectEntities,
  (projectInviteIds, entities) => denormalize(projectInviteIds, [projectInviteSchema], entities)
)

export const selectUpdateProjectInviteStatus = state => state.projectInvites.updateProjectInviteStatus
export const selectRetrieveProjectInviteStatus = state => state.projectInvites.retrieveProjectInviteStatus
export const selectRetrieveProjectInvitesStatus = state => state.projectInvites.retrieveProjectInvitesStatus
export const selectUpdateProjectInviteError = state => state.projectInvites.updateProjectInviteError
export const selectRetrieveProjectInviteError = state => state.projectInvites.retrieveProjectInviteError
export const selectRetrieveProjectInvitesError = state => state.projectInvites.retrieveProjectInvitesError


// Action Creators
export const retrieveProjectInvite = (projectInviteId) => ({
  [CALL_API]: {
    types: [PROJECT_INVITE_REQUEST, PROJECT_INVITE_SUCCESS, PROJECT_INVITE_FAILURE],
    endpoint: `project_invites/${projectInviteId}`,
    schema: projectInviteSchema
  },
})

export const updateProjectInvite = ({ projectInvite, projectInviteId }) => ({
  [CALL_API]: {
    types: [UPDATE_PROJECT_INVITE_REQUEST, UPDATE_PROJECT_INVITE_SUCCESS, UPDATE_PROJECT_INVITE_FAILURE],
    endpoint: `project_invites/${projectInviteId}`,
    method: HTTP_METHODS.PUT,
    body: { projectInvite },
    schema: projectInviteSchema
  },
})

export const retrieveProjectInvites = () => ({
  [CALL_API]: {
    types: [PROJECT_INVITES_REQUEST, PROJECT_INVITES_SUCCESS, PROJECT_INVITES_FAILURE],
    endpoint: 'project_invites',
    schema: [projectInviteSchema]
  },
})
