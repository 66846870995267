import { extractErrorFromMessage } from '../lib/utils'
import { CALL_API, HTTP_METHODS } from '../middlewares/api'


// API Actions
const SIGN_UP_REQUEST = 'steady-install/users/SIGN_UP_REQUEST'
const SIGN_UP_SUCCESS = 'steady-install/users/SIGN_UP_SUCCESS'
const SIGN_UP_FAILURE = 'steady-install/users/SIGN_UP_FAILURE'

const SIGN_IN_REQUEST = 'steady-install/users/SIGN_IN_REQUEST'
const SIGN_IN_SUCCESS = 'steady-install/users/SIGN_IN_SUCCESS'
const SIGN_IN_FAILURE = 'steady-install/users/SIGN_IN_FAILURE'

const SIGN_OUT_REQUEST = 'steady-install/users/SIGN_OUT_REQUEST'
const SIGN_OUT_SUCCESS = 'steady-install/users/SIGN_OUT_SUCCESS'
const SIGN_OUT_FAILURE = 'steady-install/users/SIGN_OUT_FAILURE'

const RETRIEVE_USER_REQUEST = 'steady-install/users/RETRIEVE_USER_REQUEST'
const RETRIEVE_USER_SUCCESS = 'steady-install/users/RETRIEVE_USER_SUCCESS'
const RETRIEVE_USER_FAILURE = 'steady-install/users/RETRIEVE_USER_FAILURE'

const UPDATE_USER_REQUEST = 'steady-install/users/UPDATE_USER_REQUEST'
const UPDATE_USER_SUCCESS = 'steady-install/users/UPDATE_USER_SUCCESS'
const UPDATE_USER_FAILURE = 'steady-install/users/UPDATE_USER_FAILURE'

const CHANGE_PASSWORD_REQUEST = 'steady-install/users/CHANGE_PASSWORD_REQUEST'
const CHANGE_PASSWORD_SUCCESS = 'steady-install/users/CHANGE_PASSWORD_SUCCESS'
const CHANGE_PASSWORD_FAILURE = 'steady-install/users/CHANGE_PASSWORD_FAILURE'

const RESET_PASSWORD_REQUEST = 'steady-install/users/RESET_PASSWORD_REQUEST'
const RESET_PASSWORD_SUCCESS = 'steady-install/users/RESET_PASSWORD_SUCCESS'
const RESET_PASSWORD_FAILURE = 'steady-install/users/RESET_PASSWORD_FAILURE'

const ACCEPT_INVITATION_REQUEST = 'steady-install/users/ACCEPT_INVITATION_REQUEST'
const ACCEPT_INVITATION_SUCCESS = 'steady-install/users/ACCEPT_INVITATION_SUCCESS'
const ACCEPT_INVITATION_FAILURE = 'steady-install/users/ACCEPT_INVITATION_FAILURE'

const UPDATE_CARD_REQUEST = 'steady-install/users/UPDATE_CARD_REQUEST'
const UPDATE_CARD_SUCCESS = 'steady-install/users/UPDATE_CARD_SUCCESS'
const UPDATE_CARD_FAILURE = 'steady-install/users/UPDATE_CARD_FAILURE'

const RETRIEVE_PAYMENT_REQUEST = 'steady-install/users/RETRIEVE_PAYMENT_REQUEST'
const RETRIEVE_PAYMENT_SUCCESS = 'steady-install/users/RETRIEVE_PAYMENT_SUCCESS'
const RETRIEVE_PAYMENT_FAILURE = 'steady-install/users/RETRIEVE_PAYMENT_FAILURE'

const EMAIL_AVAILABLE_REQUEST = 'steady-install/users/EMAIL_AVAILABLE_REQUEST'
const EMAIL_AVAILABLE_SUCCESS = 'steady-install/users/EMAIL_AVAILABLE_SUCCESS'
const EMAIL_AVAILABLE_FAILURE = 'steady-install/users/EMAIL_AVAILABLE_FAILURE'

const VERIFICATION_CODE_REQUEST = 'steady-install/users/VERIFICATION_CODE_REQUEST'
const VERIFICATION_CODE_SUCCESS = 'steady-install/users/VERIFICATION_CODE_SUCCESS'
const VERIFICATION_CODE_FAILURE = 'steady-install/users/VERIFICATION_CODE_FAILURE'

// const LOGIN_CODE_REQUEST = 'steady-install/users/LOGIN_CODE_REQUEST'
// const LOGIN_CODE_SUCCESS = 'steady-install/users/LOGIN_CODE_SUCCESS'
// const LOGIN_CODE_FAILURE = 'steady-install/users/LOGIN_CODE_FAILURE'

// Registration Actions
const SAVE_PERSONAL_INFO = 'steady-install/users/SAVE_PERSONAL_INFO'
const SAVE_MANAGER_INFO = 'steady-install/users/SAVE_MANAGER_INFO'
const SAVE_PHONE_NUMBER = 'steady-install/users/SAVE_PHONE_NUMBER'

export const USER_HOME_ROUTE = '/dashboard';
export const USER_LOGIN_ROUTE = '/login';

export { SIGN_OUT_SUCCESS }

// Initial State
const initialState = {
  me: {},
  defaultPayment: null,
  personalInfo: null,
  managerInfo: null,
  phoneNumber: null,
  verificationCodeStatus: null,
  signUpStatus: null,
  emailAvailableStatus: null,
  retrieveUserStatus: null,
  retrieveUserError: null,
  updateUserStatus: null,
  updateUserError: null,
  acceptInvitationStatus:null
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case ACCEPT_INVITATION_REQUEST:
      return {
        ...state,
        acceptInvitationStatus: "requesting"
      }
    case ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        acceptInvitationStatus: "success"
      }
    case ACCEPT_INVITATION_FAILURE: {
      const errorObj = extractErrorFromMessage(action.error);
      const isInvalid = errorObj.status === "422" && (errorObj.message.includes("invalid"))
      return {
        ...state,
        acceptInvitationStatus: isInvalid ? "invalid token" : "failed"
      }
    }
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        me: action.payload.user
      }
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUserStatus: 'requesting',
        updateUserError: null,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        me: action.payload.user,
        updateUserStatus: 'success',
        updateUserError: null,
      }
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserStatus: 'failed',
        updateUserError: action.error,
      }
    case SIGN_UP_REQUEST:
      return {
        ...state,
        signUpStatus: "requesting"
      }
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        me: action.payload.user,
        signUpStatus: "success"
      }
    case SIGN_UP_FAILURE: {
      const errorObj = extractErrorFromMessage(action.error);
      const incorrectCode = (errorObj.status === "403") && (errorObj.message.includes("Incorrect verification code"));
      return {
        ...state,
        signUpStatus: incorrectCode
          ? errorObj.message
          : `sign up failed ${errorObj.message}`
      }
    }
    case RETRIEVE_USER_REQUEST:
      return {
        ...state,
        retrieveUserStatus: "requesting",
        retrieveUserError: null,
      }
    case RETRIEVE_USER_SUCCESS:
      return {
        ...state,
        retrieveUserStatus: "success",
        retrieveUserError: null,
        me: action.payload,
      }
    case RETRIEVE_USER_FAILURE: 
      return {
        ...state,
        retrieveUserStatus: "failed",
        retrieveUserError: action.error 
      }
    case RETRIEVE_PAYMENT_SUCCESS:
      return {
        ...state,
        defaultPayment: action.payload.defaultPayment,
      }
    case SAVE_PERSONAL_INFO:
      return {
        ...state,
        personalInfo: action.payload,
      }
    case SAVE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      }
    case SAVE_MANAGER_INFO:
      return {
        ...state,
        managerInfo: action.payload,
      }
    case VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        verificationCodeStatus: "requesting"
      }
    case VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        verificationCodeStatus: "success"
      }
    case VERIFICATION_CODE_FAILURE: {
      const errorObj = extractErrorFromMessage(action.error);
      const numberTaken = (errorObj.status === "409") && (errorObj.message.includes("Phone number"));
      return {
        ...state,
        verificationCodeStatus: numberTaken
          ? errorObj.message
          : `Error sending verification code ${errorObj.message}`
      }
    }
    case EMAIL_AVAILABLE_REQUEST: {
      return {
        ...state,
        emailAvailableStatus: "requesting"
      }
    }
    case EMAIL_AVAILABLE_SUCCESS: {
      return {
        ...state,
        emailAvailableStatus: "success"
      }
    }
    case EMAIL_AVAILABLE_FAILURE: {
      const errorObj = extractErrorFromMessage(action.error);
      const emailTaken = (errorObj.status === "409") && (errorObj.message.includes("Email"));
      return {
        ...state,
        emailAvailableStatus: emailTaken
          ? errorObj.message
          : `Error checking email status ${errorObj.message}` 
      }
    }
    default:
      return state
  }
}

// Selectors
export const selectUser = state => state.users.me
export const selectUserWorker = state => state.users.me.worker
export const selectUserId = (state) => state.users.me.id;
export const selectWorkerZipCode = state => state.users.me.worker.zipCode
export const selectDefaultPayment = state => state.users.defaultPayment
export const selectWorker = state => state.users.me.worker
export const selectBusiness = state => state.users.me.business
export const selectPersonalInfo = state => state.users.personalInfo
export const selectVerificationCodeStatus = state => state.users.verificationCodeStatus
export const selectSignUpStatus = state => state.users.signUpStatus
export const selectEmailAvailableStatus = state => state.users.emailAvailableStatus
export const selectRetrieveUserStatus = state => state.users.retrieveUserStatus
export const selectRetrieveUserError = state => state.users.retrieveUserError
export const selectUpdateUserStatus = state => state.users.updateUserStatus
export const selectUpdateUserError = state => state.users.updateUserError
export const selectAcceptInvitationStatus = state => state.users.acceptInvitationStatus

// Action Creators
export const signUp = ({ user, code }) => (dispatch) => {

  const signUpAction = {

    [CALL_API]: {
      types: [SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE],
      endpoint: 'users',
      method: HTTP_METHODS.POST,
      body: { user, code },
    },
  }

  return dispatch(signUpAction)
}

export const acceptInvitation = user => dispatch => {
  const acceptInvitationAction = {
    [CALL_API]: {
      types: [ACCEPT_INVITATION_REQUEST, ACCEPT_INVITATION_SUCCESS, ACCEPT_INVITATION_FAILURE],
      endpoint: 'users/invitation',
      method: HTTP_METHODS.PUT,
      body: { user },
    },
  }

  return dispatch(acceptInvitationAction)
}

export const signIn = (user) => (dispatch) => dispatch({
  type: [SIGN_IN_SUCCESS, SIGN_IN_REQUEST, SIGN_IN_FAILURE],
  payload: user
})

export const signOut = () => (dispatch) => {
  const signOutAction = {
    [CALL_API]: {
      types: [SIGN_OUT_REQUEST, SIGN_OUT_SUCCESS, SIGN_OUT_FAILURE],
      endpoint: 'users/sign_out',
      method: HTTP_METHODS.DELETE,
    },
  }

  return dispatch(signOutAction)
    .then(() => window.scrollTo(0, 0))
}

export const retrieveUser = () => ({
  [CALL_API]: {
    types: [RETRIEVE_USER_REQUEST, RETRIEVE_USER_SUCCESS, RETRIEVE_USER_FAILURE],
    endpoint: 'users/me',
  },
})

export const updateUser = user => ({
  [CALL_API]: {
    types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    endpoint: 'users',
    method: HTTP_METHODS.PUT,
    body: { user },
  },
})

export const updatePassword = user => ({
  [CALL_API]: {
    types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE],
    endpoint: 'users',
    method: HTTP_METHODS.PUT,
    body: { user },
  },
})

export const resetPassword = user => ({
  [CALL_API]: {
    types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    endpoint: 'users/password',
    method: HTTP_METHODS.POST,
    body: { user },
  },
})

export const changePassword = user => ({
  [CALL_API]: {
    types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    endpoint: 'users/password',
    method: HTTP_METHODS.PUT,
    body: { user },
  },
})

export const updateCard = token => ({
  [CALL_API]: {
    types: [
      UPDATE_CARD_REQUEST,
      UPDATE_CARD_SUCCESS,
      UPDATE_CARD_FAILURE,
    ],
    endpoint: `users/stripe_customer_source`,
    method: HTTP_METHODS.PUT,
    body: { token },
  },
})

export const retrieveDefaultPayment = () => ({
  [CALL_API]: {
    types: [
      RETRIEVE_PAYMENT_REQUEST,
      RETRIEVE_PAYMENT_SUCCESS,
      RETRIEVE_PAYMENT_FAILURE,
    ],
    endpoint: `users/default_payment`,
  },
})

export const emailAvailable = (email) => ({
  [CALL_API]: {
    types: [EMAIL_AVAILABLE_REQUEST, EMAIL_AVAILABLE_SUCCESS, EMAIL_AVAILABLE_FAILURE],
    endpoint: `users/email_available?email=${email}`,
    method: HTTP_METHODS.GET,
    query: { email },
  },
})

export const sendVerificationCode = (phoneNumber) => ({
  [CALL_API]: {
    types: [VERIFICATION_CODE_REQUEST, VERIFICATION_CODE_SUCCESS, VERIFICATION_CODE_FAILURE],
    endpoint: 'users/send_verification_code',
    method: HTTP_METHODS.POST,
    body: { phoneNumber },
  },
})

export const savePersonalInfo = (personalInfo) => ({
  type: SAVE_PERSONAL_INFO,
  payload: personalInfo,
}) 
