import { camelizeKeys, decamelizeKeys } from "humps";
import fetch from "isomorphic-unfetch";
// import param from "jquery-param";
import Router from "next/router";
import { normalize } from "normalizr";
import url from "url";
import { removeAuthHeaders } from "../lib/session";

// Available HTTP request methods
export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol("Call API");

const UNAUTHORIZED_REDIRECT_PATH = "/auth";

/**
 * Native replacement for lodash `isPlainObject`.
 * @param {any} value - The value to check.
 * @returns {boolean} - Whether the value is a plain object.
 */
const isPlainObject = (value) =>
  typeof value === "object" && value !== null && Object.prototype.toString.call(value) === "[object Object]";

/**
 * Native replacement for lodash `isUndefined`.
 * @param {any} value - The value to check.
 * @returns {boolean} - Whether the value is undefined.
 */
// const isUndefined = (value) => value === undefined;

/**
 * Native replacement for lodash `omitBy`.
 * @param {object} obj - The object to process.
 * @param {function} predicate - Function invoked per property.
 * @returns {object} - A new object with properties omitted.
 */
// const omitBy = (obj, predicate) =>
//   Object.fromEntries(Object.entries(obj).filter(([key, value]) => !predicate(value)));

/**
 * Checks if a non - ok response is recoverable
 * @param {response} obj - The response from the api call
 * @returns {boolean}
 */
const isRecoverable = (response) => {
  //we'll retry on 429 (throttling) 502 (bad gateway) 
  //503 (service unavailable) 504 (gateway timeout)
  const retryableCodes = [429, 502, 503, 504];
  return retryableCodes.includes(response.status);
}

/**
 * Handles error response from the API and redirects on 401.
 * @param {Response} response - The fetch response object.
 * @param {object} json - Parsed JSON response.
 * @returns {Promise} - Rejects the promise with the error.
 */
const handleError = (response, json) => {
  if (response.status === 401) {
    removeAuthHeaders();
    if (Router.route !== UNAUTHORIZED_REDIRECT_PATH) {
      Router.replace(
        {
          pathname: UNAUTHORIZED_REDIRECT_PATH,
          query: { error: json.message },
        },
        UNAUTHORIZED_REDIRECT_PATH
      );
    }
  }
  return Promise.reject(camelizeKeys(json));
};

/**
 * Handles the response and normalizes data if a schema is provided.
 * @param {object} schema - The normalizr schema to use for normalization.
 * @returns {function} - Function that processes the response.
 */
const handleResponse = (schema) => (response) => {
  if (response.status === 204) {
    return response.statusText; // No content
  }
  if (response.status >= 500) {
    return Promise.reject(response.statusText); // Server error
  }
  return response.json().then((json) => {
    if (!response.ok) {
      return handleError(response, json);
    }
    const camelizedJson = camelizeKeys(json);
    return schema ? normalize(camelizedJson, schema) : camelizedJson;
  });
};

/**
 * Core API call function that performs the request and processes the response.
 * @param {string} url - API endpoint.
 * @param {string} method - HTTP method.
 * @param {object} body - Request payload.
 * @param {object} schema - Normalizr schema.
 * @param {function} getAuthHeaders - Function to retrieve auth headers.
 * @returns {Promise} - The fetch API promise.
 */
const callApi = async (url, method, body, schema, getAuthHeaders) => {
  const delay = 1000;
  const retries = 3;
  let attempts = 0;
  try {
    const sessionHeaders = await getAuthHeaders();
    const requestOptions = {
      method,
      body,
      headers: {
        Accept: "application/json",
        ...sessionHeaders,
      },
    };
    if (isPlainObject(body)) {
      requestOptions.body = JSON.stringify(decamelizeKeys(body));
      requestOptions.headers["Content-Type"] = "application/json";
    }
    let response;
    while (attempts < retries) {
      response = await fetch(url, requestOptions);
      if (response.ok) {
        return handleResponse(schema)(response);
      } else if (isRecoverable(response)) {
        attempts++;
        await new Promise((resolve) => setTimeout(resolve, delay * attempts));
      } else {
        //If the error is not recoverable there's no point retrying
        attempts = retries;
      }
    }
    //If we get to this point we've retried 3 times so there's an error
    const responseBody = await response.json();
    const message = responseBody.message
      ? JSON.stringify(responseBody.message)
      : responseBody.errors
        ? JSON.stringify(responseBody.errors)
        : "no message";
    throw new Error(`Operation failed. Status: ${response.status}, Message: ${message}`);
} catch (error) {
    console.error("Fetch error:", error);
    return Promise.reject(error); // Pass error up the chain
}
};

/**
 * Helper function to construct the full URL.
 * @param {object} store - Redux store.
 * @param {string} endpoint - API endpoint.
 * @returns {string} - The full URL.
 */
const buildFullUrl = (store, endpoint) => {
  const apiUrl = store.getState().config.apiUrl;
  if (!apiUrl) {
    throw new Error("API root URL is not defined in the Redux store configuration.");
  }
  const apiRoot = url.resolve(apiUrl, "/api/v1/");
  return endpoint.startsWith(apiRoot) ? endpoint : apiRoot + endpoint;
};

/**
 * Redux middleware for handling API calls.
 * @param {function} getAuthHeaders - Function to retrieve authentication headers.
 * @returns {function} - Middleware function for Redux.
 */
const apiMiddleware = (getAuthHeaders) => (store) => (next) => (action) => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === "undefined") {
    return next(action); // Non-API actions pass through
  }

  const { body, types, schema } = callAPI;
  let { endpoint, method } = callAPI;

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }

  const [requestType, successType, failureType] = types;

  if (!requestType || !successType || !failureType) {
    console.error("One of the action types is undefined:", { requestType, successType, failureType });
    throw new Error("Actions may not have an undefined 'type' property.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  // Dispatch the request action type
  next(actionWith({ type: requestType }));

  const fullUrl = buildFullUrl(store, endpoint); // Construct `fullUrl` using the helper

  return callApi(fullUrl, method || HTTP_METHODS.GET, body, schema, getAuthHeaders)
    .then((response) =>
      next(
        actionWith({
          payload: response,
          type: successType,
        })
      )
    )
    .catch((error) =>
      next(
        actionWith({
          type: failureType,
          error: error.message || "Something went wrong",
        })
      )
    );
};

export default apiMiddleware;
