import { useEffect } from 'react';

const ZendeskChatWidget = () => {
  useEffect(() => {
    const zendeskKey = process.env.NEXT_PUBLIC_ZENDESK_CHAT_WIDGET_KEY;
    window.zESettings = {
      webWidget: {
        chat: {
          connectOnPageLoad: true,
        }
      }
    };

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default ZendeskChatWidget;