

// Actions
const DELETE_ENTITIES = 'steady-install/entities/DELETE_ENTITIES';

// Initial State
const initialState = {
  users: {},
  projects: {},
  projectInvites: {},
  shiftInvites: {},
  timeLog: {},
  foremen: [],
};

/**
 * Custom merge function that overwrites arrays instead of merging them.
 * This replaces `mergeWith` from lo-dash.
 */
const mergeWithOverwriteArrays = (target, source) => {
  Object.keys(source).forEach((key) => {
    if (Array.isArray(target[key]) && Array.isArray(source[key])) {
      target[key] = [...source[key]]; // Overwrite arrays
    } else if (typeof target[key] === 'object' && target[key] !== null && typeof source[key] === 'object' && source[key] !== null ) {
      target[key] = mergeWithOverwriteArrays({ ...target[key] }, source[key]);
    } else {
      target[key] = source[key];
    }
  });
  return target;
};

/**
 * Custom omit function to exclude specific keys.
 * This replaces `omit` from lo-dash.
 */
const omit = (obj, keys) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)));

// Reducer
export default (state = initialState, action) => {
  if (action.payload && action.payload.entities) {
    return mergeWithOverwriteArrays(state, action.payload.entities);
  }
  if (action.type === DELETE_ENTITIES) {
    return omit(state, action.payload);
  }
  return state;
};

// Selectors
export const selectEntities = (state) => state.entities;

// Action Creators
export const deleteEntities = (paths) => ({
  type: DELETE_ENTITIES,
  payload: paths,
});
