// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"CNwRP4jtYJC-Fo6PvD1iJ"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  maxBreadcrumbs: 50,
  release: "si-web@" + process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? "1.0",

  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      unmask: ['.sentry-unmask', '[data-sentry-unmask]'],
      unblock: ['.sentry-unblock', '[data-sentry-unblock]'],
      networkDetailAllowUrls: [
        window.location.origin,
        "https://admin-old.steadyinstall.com",
        "https://app.steadyinstall.com",
        "https://steady-install-web-dev-emmj.zeet-steady-install.zeet.app",
        /^https:\/\/admin-old\.steadyinstall\.com/,
        /^https:\/\/app\.steadyinstall\.com/
      ],
      networkRequestHeaders: ["Cache-Control", "Authorization"],
      networkResponseHeaders: ["Referrer-Policy", "X-Content-Type-Options"],
    }),
    Sentry.feedbackIntegration({
      colorScheme: 'dark',
      isNameRequired: true,
      id: 'sentry-feedback',
    }),
    Sentry.captureConsoleIntegration({
      levels: ['log', 'info', 'warn', 'error', 'debug', 'assert'],
    }),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: ['admin-old.steadyinstall.com'],
    }),
    Sentry.sessionTimingIntegration(),
    Sentry.browserApiErrorsIntegration({
      setTimeout: true,
      setInterval: true,
      requestAnimationFrame: true,
      XMLHttpRequest: true,
      eventTarget: true,
    }),
  ],
});
