import { createSelector } from 'reselect'
import { CALL_API, HTTP_METHODS } from '../middlewares/api'
import { denormalize } from 'normalizr'
import { selectEntities } from './entities'
import { timeLog as timeLogSchema} from '../lib/schema'


// API Actions
const TIME_LOG_REQUEST = 'steady-install/projectInvites/TIME_LOG_REQUEST'
const TIME_LOG_SUCCESS = 'steady-install/projectInvites/TIME_LOG_SUCCESS'
const TIME_LOG_FAILURE = 'steady-install/projectInvites/TIME_LOG_FAILURE'

const UPDATE_TIME_LOG_REQUEST = 'steady-install/projectInvites/UPDATE_TIME_LOG_REQUEST'
const UPDATE_TIME_LOG_SUCCESS = 'steady-install/projectInvites/UPDATE_TIME_LOG_SUCCESS'
const UPDATE_TIME_LOG_FAILURE = 'steady-install/projectInvites/UPDATE_TIME_LOG_FAILURE'


// Initial State
const initialState = {
  timeLogId: null,
  retrieveTimeLogStatus: null,
  retrieveTimeLogError: null,
  updateTimeLogStatus: null,
  updateTimeLogError:null,
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case TIME_LOG_REQUEST:
      return {
        ...state,
        retrieveTimeLogError: null,
        retrieveTimeLogStatus: "requesting"
      }
    case TIME_LOG_SUCCESS:
      return {
        ...state,
        retrieveTimeLogError: null,
        timeLogId: action.payload?.result,
        retrieveTimeLogStatus: "success"
      }
    case TIME_LOG_FAILURE:
      return {
        ...state,
        retrieveTimeLogError: action.error,
        retrieveTimeLogStatus: "failed"
      }
    case UPDATE_TIME_LOG_REQUEST:
      return {
        ...state,
        updateTimeLogStatus: "requesting",
        updateTimeLogError: null,
      }
    case UPDATE_TIME_LOG_SUCCESS:
      return {
        ...state,
        timeLogId: action.payload?.result,
        updateTimeLogError: null,
        updateTimeLogStatus: "success"
      }
    case UPDATE_TIME_LOG_FAILURE:
      return {
        ...state,
        updateTimeLogError: action.error,
        updateTimeLogStatus: "failed"
      }
    default:
      return state
  }
}


// Selectors
export const selectTimeLogId = state => state.timeLogs.timeLogId
export const selectTimeLog = createSelector(
  selectTimeLogId,
  selectEntities,
  (timeLogId, entities) => denormalize(timeLogId, timeLogSchema, entities)
)
export const selectRetrieveTimeLogStatus = state => state.timeLogs.retrieveTimeLogStatus;
export const selectRetrieveTimeLogError = state => state.timeLogs.retrieveTimeLogError;
export const selectUpdateTimeLogStatus = state => state.timeLogs.updateTimeLogStatus;
export const selectUpdateTimeLogError = state => state.timeLogs.updateTimeLogError;

// Action Creators
export const retrieveTimeLog = (timeLogId) => ({
  [CALL_API]: {
    types: [TIME_LOG_REQUEST, TIME_LOG_SUCCESS, TIME_LOG_FAILURE],
    endpoint: `time_logs/${timeLogId}`,
    schema: timeLogSchema
  },
})

export const updateTimeLog = ({ timeLog, timeLogId }) => ({
  [CALL_API]: {
    types: [UPDATE_TIME_LOG_REQUEST, UPDATE_TIME_LOG_SUCCESS, UPDATE_TIME_LOG_FAILURE],
    endpoint: `time_logs/${timeLogId}`,
    method: HTTP_METHODS.PUT,
    body: { timeLog },
    schema: timeLogSchema
  },
})
