import { createSelector } from "reselect";
import { CALL_API, HTTP_METHODS } from "../middlewares/api";
import { denormalize } from "normalizr";
import { selectEntities } from "./entities";
import { shiftInvite as shiftInviteSchema } from "../lib/schema";

// API Actions
const SHIFT_INVITE_REQUEST = "steady-install/shiftInvites/SHIFT_INVITE_REQUEST";
const SHIFT_INVITE_SUCCESS = "steady-install/shiftInvites/SHIFT_INVITE_SUCCESS";
const SHIFT_INVITE_FAILURE = "steady-install/shiftInvites/SHIFT_INVITE_FAILURE";

const UPDATE_SHIFT_INVITE_REQUEST =
  "steady-install/shiftInvites/UPDATE_SHIFT_INVITE_REQUEST";
const UPDATE_SHIFT_INVITE_SUCCESS =
  "steady-install/shiftInvites/UPDATE_SHIFT_INVITE_SUCCESS";
const UPDATE_SHIFT_INVITE_FAILURE =
  "steady-install/shiftInvites/UPDATE_SHIFT_INVITE_FAILURE";

const SHIFT_INVITES_REQUEST =
  "steady-install/shiftInvites/SHIFT_INVITES_REQUEST";
const SHIFT_INVITES_SUCCESS =
  "steady-install/shiftInvites/SHIFT_INVITES_SUCCESS";
const SHIFT_INVITES_FAILURE =
  "steady-install/shiftInvites/SHIFT_INVITES_FAILURE";

// Initial State
const initialState = {
  shiftInviteId: null,
  shiftInviteIds: null,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case SHIFT_INVITE_SUCCESS:
    case UPDATE_SHIFT_INVITE_SUCCESS:
      return {
        ...state,
        shiftInviteId: action.payload.result,
      };
    case SHIFT_INVITES_SUCCESS:
      return {
        ...state,
        shiftInviteIds: action.payload.result,
      };
    default:
      return state;
  }
};

// Selectors
export const selectShiftInviteId = (state) => state.shiftInvites.shiftInviteId;
export const selectShiftInvite = createSelector(
  selectShiftInviteId,
  selectEntities,
  (shiftInviteId, entities) =>
    denormalize(shiftInviteId, shiftInviteSchema, entities)
);

export const selectShiftInviteIds = (state) =>
  state.shiftInvites.shiftInviteIds;
export const selectShiftInvites = createSelector(
  selectShiftInviteIds,
  selectEntities,
  (shiftInviteIds, entities) =>
    denormalize(shiftInviteIds, [shiftInviteSchema], entities)
);

// Action Creators
export const retrieveShiftInvite = (shiftInviteId) => ({
  [CALL_API]: {
    types: [SHIFT_INVITE_REQUEST, SHIFT_INVITE_SUCCESS, SHIFT_INVITE_FAILURE],
    endpoint: `shift_invites/${shiftInviteId}`,
    schema: shiftInviteSchema,
  },
});

export const updateShiftInvite = ({ shiftInvite, shiftInviteId }) => ({
  [CALL_API]: {
    types: [
      UPDATE_SHIFT_INVITE_REQUEST,
      UPDATE_SHIFT_INVITE_SUCCESS,
      UPDATE_SHIFT_INVITE_FAILURE,
    ],
    endpoint: `shift_invites/${shiftInviteId}`,
    method: HTTP_METHODS.PUT,
    body: { shiftInvite },
    schema: shiftInviteSchema,
  },
});

export const retrieveShiftInvites = () => ({
  [CALL_API]: {
    types: [
      SHIFT_INVITES_REQUEST,
      SHIFT_INVITES_SUCCESS,
      SHIFT_INVITES_FAILURE,
    ],
    endpoint: "shift_invites",
    schema: [shiftInviteSchema],
  },
});
