import { CALL_API, HTTP_METHODS } from '../middlewares/api'
import {
  foremen as foremenSchema,
  foreman as foremanSchema
} from '../lib/schema'

const RETRIEVE_FOREMEN_REQUEST = 'steady-install/foremen/RETRIEVE_FOREMEN_REQUEST'
const RETRIEVE_FOREMEN_SUCCESS = 'steady-install/foremen/RETRIEVE_FOREMEN_SUCCESS'
const RETRIEVE_FOREMEN_FAILURE = 'steady-install/foremen/RETRIEVE_FOREMEN_FAILURE'

const RETRIEVE_FOREMAN_REQUEST = 'steady-install/foremen/RETRIEVE_FOREMAN_REQUEST'
const RETRIEVE_FOREMAN_SUCCESS = 'steady-install/foremen/RETRIEVE_FOREMAN_SUCCESS'
const RETRIEVE_FOREMAN_FAILURE = 'steady-install/foremen/RETRIEVE_FOREMAN_FAILURE'

const CREATE_FOREMAN_REQUEST = 'steady-install/foremen/CREATE_FOREMAN_REQUEST'
const CREATE_FOREMAN_SUCCESS = 'steady-install/foremen/CREATE_FOREMAN_SUCCESS'
const CREATE_FOREMAN_FAILURE = 'steady-install/foremen/CREATE_FOREMAN_FAILURE'

const EDIT_FOREMAN_REQUEST = 'steady-install/foremen/EDIT_FOREMAN_REQUEST'
const EDIT_FOREMAN_SUCCESS = 'steady-install/foremen/EDIT_FOREMAN_SUCCESS'
const EDIT_FOREMAN_FAILURE = 'steady-install/foremen/EDIT_FOREMAN_FAILURE'

const DELETE_FOREMAN_REQUEST = 'steady-install/foremen/DELETE_FOREMAN_REQUEST'
const DELETE_FOREMAN_SUCCESS = 'steady-install/foremen/DELETE_FOREMAN_SUCCESS'
const DELETE_FOREMAN_FAILURE = 'steady-install/foremen/DELETE_FOREMAN_FAILURE'

const PHONE_AVAILABLE_REQUEST = 'steady-install/foremen/PHONE_AVAILABLE_FOREMAN_REQUEST'
const PHONE_AVAILABLE_SUCCESS = 'steady-install/foremen/PHONE_AVAILABLE_FOREMAN_SUCCESS'
const PHONE_AVAILABLE_FAILURE = 'steady-install/foremen/PHONE_AVAILABLE_FOREMAN_FAILURE'

// Initial State
const initialState = {
  foremen: [],
  foreman: {},
  selectedForeman: {},
  createForemanStatus: null,
  createForemanError: null,
  retrieveForemanStatus: null,
  retrieveForemenStatus: null,
  editForemanStatus: null,
  editForemanError: null,
  retrieveForemanError: null,
  retrieveForemenError: null,
  deleteForemanStatus: null,
  deleteForemanError: null,
}
// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_FOREMAN_REQUEST:
      return {
        ...state,
        retrieveFormanStatus: "requesting",
        retrieveForemanError: null,
      }
    case RETRIEVE_FOREMAN_SUCCESS:
      return {
        ...state,
        foreman: action.payload.result,
        retrieveForemanStatus: "success",
        retrieveForemanError: null,
      }
    case RETRIEVE_FOREMAN_FAILURE:
      return {
        ...state,
        retrieveForemanStatus: "failed",
        retrieveForemanError: action.error
      }
    case RETRIEVE_FOREMEN_REQUEST:
      return {
        ...state,
        retrieveForemenStatus: "requesting",
        retrieveForemenError: null,
      }
    case RETRIEVE_FOREMEN_SUCCESS:
      return {
        ...state,
        retrieveForemenStatus: "success",
        retrieveForemenError: null,
        foremen: action.payload.result,
        foreman: action.payload.result
      }
    case RETRIEVE_FOREMEN_FAILURE:
      return {
        ...state,
        retrieveForemenStatus: "failed",
        retrieveForemenError: action.error
      }
    case CREATE_FOREMAN_REQUEST:
      return {
        ...state,
        foremanCreateStatus: 'requested',
        createForemanError: null
      }
    case CREATE_FOREMAN_SUCCESS: {
      const newForeman = action.payload.entities.foreman[action.payload.result];
      const newForemen = [...state.foremen, newForeman];
      return {
        ...state,
        foreman: newForeman,
        foremanId: action.payload.result,
        foremen: newForemen,
        foremanCreateStatus: 'success',
        createForemanError: null
      }
    }
    case CREATE_FOREMAN_FAILURE:
      return {
        ...state,
        createForemanError: action.error,
        foremanCreateStatus: 'failed'
      }
    case EDIT_FOREMAN_REQUEST:
      return {
        ...state,
        editForemanStatus: "requesting",
        editForemanError: null,
      }
    case EDIT_FOREMAN_SUCCESS:
      return {
        ...state,
        editForemanStatus: "success",
        editForemanError: null,
        foreman: action.payload.result
      }
    case EDIT_FOREMAN_FAILURE:
      return {
        ...state,
        editForemanStatus: "failed",
        editForemanError: action.error,
      }
    case PHONE_AVAILABLE_SUCCESS:
      return {
        ...state,
        foreman: action.payload.result
      }
    case DELETE_FOREMAN_REQUEST:
      return {
        ...state,
        deleteForemanStatus: "requesting",
        deleteForemanError: null
      }
    case DELETE_FOREMAN_SUCCESS:
      return {
        ...state,
        deleteForemanStatus: "success",
        deleteForemanError: null,
        foremen: state.foremen.filter((foreman) => foreman.id !== action.payload.foremanId),
      }
    case DELETE_FOREMAN_FAILURE:
      return {
        ...state,
        deleteForemanStatus: "failed",
        deleteForemanError: action.error
      }
    default:
      return state
  }
}

// Selectors 
export const selectForemen = state => state.foreman.foremen
export const selectForeman = state => state.foreman.foreman
export const selectForemanId = state => state.foreman.foremanId
export const selectCreateForemanStatus = state => state.foreman.foremanCreateStatus
export const selectCreateForemanError = state => state.foreman.createForemanError
export const selectRetrieveForemanStatus = state => state.foreman.retrieveForemanStatus
export const selectRetrieveForemanError = state => state.foreman.retrieveForemanError
export const selectRetrieveForemenStatus = state => state.foreman.retrieveForemenStatus
export const selectRetrieveForemenError = state => state.foreman.retrieveForemenError
export const selectEditForemanStatus = state => state.foreman.editForemanStatus
export const selectEditForemanError = state => state.foreman.editForemanError
export const selectDeleteForemanStatus = state => state.foreman.deleteForemanStatus
export const selectDeleteForemanError = state => state.foreman.deleteForemanError
// Action Creators

export const retrieveForemen = (page = 1) => ({
  [CALL_API]: {
    types: [RETRIEVE_FOREMEN_REQUEST, RETRIEVE_FOREMEN_SUCCESS, RETRIEVE_FOREMEN_FAILURE],
    endpoint: `foremen/user_foremen?page=${page}`,  // Append page to the endpoint
    method: HTTP_METHODS.GET,
    schema: [foremenSchema],
  },
});


export const isPhoneAvailable = (phone) => ({
  [CALL_API]: {
    types: [PHONE_AVAILABLE_REQUEST, PHONE_AVAILABLE_SUCCESS, PHONE_AVAILABLE_FAILURE],
    endpoint: 'foremen/phone_available',
    method: HTTP_METHODS.GET,
    query: { phone },
  },
})

export const createForeman = (foreman) => ({
  [CALL_API]: {
    types: [CREATE_FOREMAN_REQUEST, CREATE_FOREMAN_SUCCESS, CREATE_FOREMAN_FAILURE],
    endpoint: 'foremen',
    method: HTTP_METHODS.POST,
    body: { foreman },
    schema: foremanSchema
  },
})

export const updateForeman = (foreman, foremanId) => ({
  [CALL_API]: {
    types: [EDIT_FOREMAN_REQUEST, EDIT_FOREMAN_SUCCESS, EDIT_FOREMAN_FAILURE],
    endpoint: `foremen/${foremanId}`,
    method: HTTP_METHODS.PUT,
    body: { foreman },
    schema: foremanSchema
  },
})

export const retrieveForeman = (foremanId) => ({
  [CALL_API]: {
    types: [RETRIEVE_FOREMAN_REQUEST, RETRIEVE_FOREMAN_SUCCESS, RETRIEVE_FOREMAN_FAILURE],
    endpoint: `foremen/${foremanId}`,
  },
})

export const softDeleteForeman = (foremanId) => ({
  [CALL_API]: {
    types: [DELETE_FOREMAN_REQUEST, DELETE_FOREMAN_SUCCESS, DELETE_FOREMAN_FAILURE],
    endpoint: `foremen/remove_association/${foremanId}`,
    method: HTTP_METHODS.PUT
  }
});