// eslint-disable-next-line no-multi-assign
exports = module.exports = {}
const { FORM_ERROR } = require('final-form')
const mapValues = require('lodash/mapValues')
const startCase = require('lodash/startCase')
const moment = require('moment')

exports.sleep = delay => new Promise(resolve => setTimeout(resolve, delay))

/**
 * Return a function that can accept an error returned by our API and returns
 * an error object to be used by Final Form.
 *
 * see https://github.com/final-form/final-form#onsubmit-values-object-callback-errors-object--void--object--promiseobject--void
 * param {string} [defaultErrorMessage] The error message to use if one is
 *   not provided by the API
 * @return {function}
 */
exports.mapFinalFormErrors = (defaultErrorMessage = 'An error occurred.') => (serverError) => {
  const { errors, message } = serverError

  const formErrors = mapValues(errors, (error, name) => (
    `${startCase(name)} ${error}`
  ))

  formErrors[FORM_ERROR] = message || defaultErrorMessage

  return formErrors
}

// returns time from now in hours
exports.timeDiffFromNowHours = (time) => moment(time, 'HH:mm').diff(moment(), 'hours', true)

/**
 * Boolean to Yes/No String
 *
 * @description used in format prop of yes/no Final-Form radios to store Yes/No as true/false.
 *
 * @param {string} [yesStr="Yes"]        - String value of "Yes" radio selection.
 * @param {string} [noStr="No"]          - String value of "No" radio selection.
 * @returns {undefined|string}           - String for matching boolean otherwise undefined.
 */
exports.formatBoolToString = (yesStr = 'Yes', noStr = "No") => value => {
  switch (value) {
    case true:
      return yesStr
    case false:
      return noStr
    default:
      return undefined
  }
}

/**
 * Convert File Object to Base64
 *
 * @see https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
 *
 * @param {*} file            - File object
 * @returns {Promise<string>} - base64 string of file object
 */
exports.toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

exports.capitaliseFirst = (input) => {
  if (input.length === 0) return;
  return `${input[0].toUpperCase()}${input.slice(1)}`;
}

//Error message is in form `Network error - status: XXX, body: MSG`
exports.extractErrorFromMessage = (message) => {
  const status = message.indexOf("status") > -1 
    ? message.substring(message.indexOf("status")+8,message.indexOf("status")+11)
    : "unknown error code";
  const errMsg = message.indexOf("body") > -1
    ? message.substring(message.indexOf("body")+6)
    : "no message";
  return {
    status,
    message: errMsg
  }
} 