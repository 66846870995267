import { signOut, getSession } from 'next-auth/react'

// const getSession = async (req) => {
//   const session = ''
//   if (session && !session.user) return null
//   return session
// }

export const getAuthHeaders = async () => {
  const session = await getSession();
  return session ? {
    'X-USER-EMAIL': session.user.email,
    'X-USER-TOKEN': session.accessToken,
  }: undefined;
};


export const removeAuthHeaders = async () => {
  await signOut()
}

export const isSignedIn = async () => {
  let ssn29 = await getSession();
  return Boolean(ssn29)
}
