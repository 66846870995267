/* eslint-disable no-case-declarations */
import { createSelector } from 'reselect'
import { CALL_API, HTTP_METHODS } from '../middlewares/api'
import { normalize, denormalize } from 'normalizr'
import { selectEntities } from './entities'
import {
  project as projectSchema,
  manufacturer as manufacturerSchema,
  workerType as workerTypeSchema,
  siteRequirement as siteRequirementSchema,
} from '../lib/schema'
import queryObjToString from "../lib/queryObjToString"

const CREATE_PROJECT_REQUEST = 'steady-install/projects/CREATE_PROJECT_REQUEST'
const CREATE_PROJECT_SUCCESS = 'steady-install/projects/CREATE_PROJECT_SUCCESS'
const CREATE_PROJECT_FAILURE = 'steady-install/projects/CREATE_PROJECT_FAILURE'

const EDIT_PROJECT_REQUEST = 'steady-install/projects/EDIT_PROJECT_REQUEST'
const EDIT_PROJECT_SUCCESS = 'steady-install/projects/EDIT_PROJECT_SUCCESS'
const EDIT_PROJECT_FAILURE = 'steady-install/projects/EDIT_PROJECT_FAILURE'

const RETRIEVE_PROJECT_REQUEST = 'steady-install/projects/RETRIEVE_PROJECT_REQUEST'
const RETRIEVE_PROJECT_SUCCESS = 'steady-install/projects/RETRIEVE_PROJECT_SUCCESS'
const RETRIEVE_PROJECT_FAILURE = 'steady-install/projects/RETRIEVE_PROJECT_FAILURE'

const RETRIEVE_PROJECTS_REQUEST = 'steady-install/projects/RETRIEVE_PROJECTS_REQUEST'
const RETRIEVE_PROJECTS_SUCCESS = 'steady-install/projects/RETRIEVE_PROJECTS_SUCCESS'
const RETRIEVE_PROJECTS_FAILURE = 'steady-install/projects/RETRIEVE_PROJECTS_FAILURE'

const RETRIEVE_MANUFACTURERS_REQUEST = 'steady-install/manufacturers/RETRIEVE_MANUFACTURERS_REQUEST'
const RETRIEVE_MANUFACTURERS_SUCCESS = 'steady-install/manufacturers/RETRIEVE_MANUFACTURERS_SUCCESS'
const RETRIEVE_MANUFACTURERS_FAILURE = 'steady-install/manufacturers/RETRIEVE_MANUFACTURERS_FAILURE'

const RETRIEVE_SITE_REQUIREMENTS_REQUEST = 'steady-install/projects/RETRIEVE_SITE_REQUIREMENTS_REQUEST'
const RETRIEVE_SITE_REQUIREMENTS_SUCCESS = 'steady-install/projects/RETRIEVE_SITE_REQUIREMENTS_SUCCESS'
const RETRIEVE_SITE_REQUIREMENTS_FAILURE = 'steady-install/projects/RETRIEVE_SITE_REQUIREMENTS_FAILURE'

const RETRIEVE_WORKER_TYPES_REQUEST = 'steady-install/workerTypes/RETRIEVE_WORKER_TYPES_REQUEST'
const RETRIEVE_WORKER_TYPES_SUCCESS = 'steady-install/workerTypes/RETRIEVE_WORKER_TYPES_SUCCESS'
const RETRIEVE_WORKER_TYPES_FAILURE = 'steady-install/workerTypes/RETRIEVE_WORKER_TYPES_FAILURE'

const DELETE_PROJECT_REQUEST = 'steady-install/projects/DELETE_PROJECT_REQUEST'
const DELETE_PROJECT_SUCCESS = 'steady-install/projects/DELETE_PROJECT_SUCCESS'
const DELETE_PROJECT_FAILURE = 'steady-install/projects/DELETE_PROJECT_FAILURE'

// const REPLACEMENTS_REQUEST = 'steady-install/projects/replacements_request'
const REPLACEMENTS_SUCCESS = 'steady-install/projects/replacements_success'
// const REPLACEMENTS_FAILURE = 'steady-install/projects/replacements_failure'

const CREATE_FOREMAN_PROJECT_SUCCESS = 'steady-install/projects/CREATE_FOREMAN_PROJECT_SUCCESS'
// const CREATE_FOREMAN_PROJECT_FAILURE = 'steady-install/projects/CREATE_FOREMAN_PROJECT_REQUEST'


// Initial State
const initialState = {
  project: {},
  projectId: null,
  projectIds: [],
  manufacturerIds: [],
  siteRequirementIds: [],
  workerTypeIds: [],
  projectDatesAttributes: [],
  wantsReplacements: false,
  outOfTown: false,
  pageCount: 0,
  foremen: [],
  retrieveProjectStatus: null,
  retrieveProjectError: null,
  retrieveProjectsStatus: null,
  retrieveProjectsError: null,
  createProjectStatus: null,
  createProjectError: null,
  editProjectStatus: null,
  editProjectError: null,
  deleteProjectStatus: null,
  deleteProjectError: null,
  retrieveManufacturersStatus: null,
  retrieveManufacturersError: null,
  retrieveWorkerTypesStatus: null,
  retrieveWorkerTypesError: null,
  retrieveSiteRequirementsStatus: null,
  retrieveSiteRequirementsError: null,
}
// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROJECT_REQUEST:
      return {
        ...state,
        createProjectStatus: "requesting",
        createProjectError: null,
      }
    case CREATE_PROJECT_SUCCESS:
      const projectId = action.payload?.result;
      return {
        ...state,
        project: action.payload.entities?.projects[projectId],
        projectId,
        createProjectStatus: "success",
        createProjectError: null
      }
    case CREATE_PROJECT_FAILURE:
      return {
        ...state,
        project: undefined,
        createProjectStatus: "failed",
        createProjectError: action.error
      }
    case EDIT_PROJECT_REQUEST:
      return {
        ...state,
        editProjectStatus: "requesting",
        editProjectError: null,
      }
    case EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload.result,
        editProjectStatus: "success",
        editProjectError: null
      }
    case EDIT_PROJECT_FAILURE:
      return {
        ...state,
        project: undefined,
        editProjectStatus: "failed",
        editProjectError: action.error
      }
    case DELETE_PROJECT_REQUEST:
      return {
        ...state,
        deleteProjectStatus: "requesting",
        deleteProjectError: null
      }
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        deleteProjectStatus: "success",
        deleteProjectError: null
      }
    case DELETE_PROJECT_FAILURE:
      return {
        ...state,
        deleteProjectStatus: "failed",
        deleteProjectError: action.error
      }
    case CREATE_FOREMAN_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload.result,
      }
    case RETRIEVE_PROJECT_REQUEST:
      return {
        ...state,
        retrieveProjectError: null,
        retrieveProjectStatus: "requesting",
      }
    case RETRIEVE_PROJECT_SUCCESS:
      const projectData = action.payload.entities.projects[action.payload.result];
      return {
        ...state,
        retrieveProjectError: null,
        retrieveProjectStatus: "success",
        project: projectData,
        projectId: projectData.id,
      };
    case RETRIEVE_PROJECT_FAILURE:
      return {
        ...state,
        retrieveProjectStatus: "failed",
        retrieveProjectError: action.error
      }
    case RETRIEVE_PROJECTS_REQUEST:
      return {
        ...state,
        retrieveProjectsStatus: "requesting",
        retrieveProjectsError: null
      }
    case RETRIEVE_PROJECTS_SUCCESS:
      const newProjectIds = action.payload.result?.projects || [];
      const newEntities = action.payload.entities || {};
      return {
        ...state,
        retrieveProjectsStatus: "success",
        retrieveProjectsError: null,
        projectIds: newProjectIds,
        pageCount: action.payload.pageCount,
        entities: { ...state.entities, ...newEntities },
      };
    case RETRIEVE_PROJECTS_FAILURE:
      return {
        ...state,
        retrieveProjectsStatus: "failed",
        retrieveProjectsError: action.error
      };
    case RETRIEVE_MANUFACTURERS_REQUEST:
      return {
        ...state,
        retrieveManufacturersStatus: "requesting",
        retrieveManufacturersError: null
      }
    case RETRIEVE_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        retrieveManufacturersStatus: "success",
        retrieveManufacturersError: null,
        manufacturerIds: action.payload.result,
      }
    case RETRIEVE_MANUFACTURERS_FAILURE:
      return {
        ...state,
        retrieveManufacturersStatus: "failed",
        retrieveManufacturersError: action.error
      }
    case RETRIEVE_WORKER_TYPES_REQUEST:
      return {
        ...state,
        retrieveWorkerTypesStatus: "requesting",
        retrieveWorkerTypesError: null,
      }
    case RETRIEVE_WORKER_TYPES_SUCCESS:
      return {
        ...state,
        retrieveWorkerTypesStatus: "success",
        retrieveWorkerTypesError: null,
        workerTypeIds: action.payload.result,
      }
    case RETRIEVE_WORKER_TYPES_FAILURE:
      return {
        ...state,
        retrieveWorkerTypesStatus: "failed",
        retrieveWorkerTypesError: action.error
      }
    case RETRIEVE_SITE_REQUIREMENTS_REQUEST:
      return {
        ...state,
        retrieveSiteRequirementsStatus: "requesting",
        retrieveSiteRequirementsError: null
      }
    case RETRIEVE_SITE_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        retrieveSiteRequirementsStatus: "success",
        retrieveSiteRequirementsError: null,
        siteRequirementIds: action.payload.result,
      }
    case RETRIEVE_SITE_REQUIREMENTS_FAILURE:
      return {
        ...state,
        retrieveSiteRequirementsStatus: "failed",
        retrieveSiteRequirementsError: action.error
      }
    case REPLACEMENTS_SUCCESS:
      return {
        ...state,
        wantsReplacements: action.payload.result,
      }
    case 'SET_PROJECT_DATES_ATTRIBUTES':
      return {
        ...state,
        projectDatesAttributes: action.payload,
      };
    default:
      return state
  }
}


// Selectors
export const selectProjectId = state => state.projects.projectId
export const selectProjectEntities = (state) => state.entities.projects;
export const selectProjectIds = state => state.projects.projectIds
export const selectManufacturerIds = state => state.manufacturers.manufacturerIds
export const selectSiteRequirementIds = state => state.projects.siteRequirementIds
export const selectWorkerTypeIds = state => state.workerTypes.workerTypeIds
export const selectProjectsPageCount = state => state.projects.pageCount
export const setSelectReplacements = state => state.projects.wantsReplacements
export const selectCreateProjectStatus = state => state.projects.createProjectStatus
export const selectCreateProjectError = state => state.projects.createProjectError
export const selectEditProjectStatus = state => state.projects.editProjectStatus
export const selectEditProjectError = state => state.projects.editProjectError
export const selectRetrieveProjectStatus = state => state.projects.retrieveProjectStatus
export const selectRetrieveProjectError = state => state.projects.retrieveProjectError
export const selectRetrieveSiteRequirementsStatus = state => state.projects.retrieveSiteRequirementsStatus
export const selectRetrieveSiteRequirementsError = state => state.projects.retrieveSiteRequirementsError
export const selectRetrieveProjectsStatus = state => state.projects.retrieveProjectsStatus
export const selectRetrieveProjectsError = state => state.projects.retrieveProjectsError
export const selectDeleteProjectStatus = state => state.projects.deleteProjectStatus
export const selectDeleteProjectError = state => state.projects.deleteProjectError
export const selectRetrieveManufacturersStatus = state => state.projects.retrieveManufacturersStatus
export const selectRetrieveManufacturersError = state => state.projects.retrieveManufacturersError
export const selectRetrieveWorkerTypesStatus = state => state.projects.retrieveWorkerTypesStatus
export const selectRetrieveWorkerTypesError = state => state.projects.retrieveWorkerTypesError

export const selectProject = createSelector(
  [selectProjectId, selectProjectEntities],
  (projectId, projects) => {
    return projects[projectId] || null;
  }
);

export const selectProjects = createSelector(
  selectProjectIds,    // Check if these IDs are populated correctly
  selectEntities,
  (projectIds = [], entities = {}) => { // Fallback to empty structures
    const projects = denormalize(projectIds, [projectSchema], entities);
    return projects;
  }
);


export const selectManufacturers = createSelector(
  selectEntities,
  selectManufacturerIds,
  (entities, manufacturerIds) => denormalize(manufacturerIds, [manufacturerSchema], entities)
    .map(({ id, manufacturerName }) => ({
      value: id,
      label: manufacturerName,
    })),
)

export const selectSiteRequirements = createSelector(
  selectEntities,
  selectSiteRequirementIds,
  (entities, siteRequirementIds) => denormalize(siteRequirementIds, [siteRequirementSchema], entities)
    .map(({ id, name }) => ({
      value: id,
      label: name,
    })),
)

export const selectWorkerTypes = createSelector(
  selectEntities,
  selectWorkerTypeIds,
  (entities, workerTypeIds) => denormalize(workerTypeIds, [workerTypeSchema], entities)
)

export const selectWorkerTypesByIds = createSelector(
  [selectEntities, (state, workerTypeIds) => workerTypeIds],
  (entities, workerTypeIds) => {
    return denormalize(workerTypeIds, [workerTypeSchema], entities);
  }
);

// Action Creators

export const setProjectDatesAttributes = (projectDatesAttributes) => ({
  type: 'SET_PROJECT_DATES_ATTRIBUTES',
  payload: projectDatesAttributes,
});

export const createProject = (project) => ({
  [CALL_API]: {
    types: [CREATE_PROJECT_REQUEST, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE],
    endpoint: 'projects',
    method: HTTP_METHODS.POST,
    body: { project },
    schema: projectSchema
  },
})

export const createWorkers = (project) => ({
  [CALL_API]: {
    types: [CREATE_PROJECT_REQUEST, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE],
    endpoint: 'projects',
    method: HTTP_METHODS.POST,
    body: { project },
    schema: projectSchema
  },
})

export const editProject = (project, projectId) => ({
  [CALL_API]: {
    types: [EDIT_PROJECT_REQUEST, EDIT_PROJECT_SUCCESS, EDIT_PROJECT_FAILURE],
    endpoint: `projects/${projectId}`,
    method: HTTP_METHODS.PUT,
    body: { project },
    schema: projectSchema
  },
})

export const retrieveProject = (projectId) => ({
  [CALL_API]: {
    types: [RETRIEVE_PROJECT_REQUEST, RETRIEVE_PROJECT_SUCCESS, RETRIEVE_PROJECT_FAILURE],
    endpoint: `projects/${projectId}`,
    schema: projectSchema
  },
})

export const retrieveProjects = (queryObj = {}) => ({
  [CALL_API]: {
    types: [
      RETRIEVE_PROJECTS_REQUEST,
      {
        type: RETRIEVE_PROJECTS_SUCCESS,
        payload: async (action, state, res) => {
          const data = await res.json();
          const normalizedData = normalize(data.projects, [projectSchema]);

          return {
            entities: normalizedData.entities,
            result: normalizedData.result,
            pageCount: data.page_count,
            totalProjects: data.total_count,
          };
        },
      },
      RETRIEVE_PROJECTS_FAILURE,
    ],
    endpoint: `projects?${queryObjToString(queryObj)}`,
  },
});

export const retrieveManufacturers = () => ({
  [CALL_API]: {
    types: [RETRIEVE_MANUFACTURERS_REQUEST, RETRIEVE_MANUFACTURERS_SUCCESS, RETRIEVE_MANUFACTURERS_FAILURE],
    endpoint: 'manufacturers',
    schema: [manufacturerSchema],
  },
})

export const retrieveSiteRequirements = () => ({
  [CALL_API]: {
    types: [RETRIEVE_SITE_REQUIREMENTS_REQUEST, RETRIEVE_SITE_REQUIREMENTS_SUCCESS, RETRIEVE_SITE_REQUIREMENTS_FAILURE],
    endpoint: 'site_requirements',
    schema: [siteRequirementSchema],
  },
})
export const retrieveWorkerTypes = ({ marketId }) => ({
  [CALL_API]: {
    types: [RETRIEVE_WORKER_TYPES_REQUEST, RETRIEVE_WORKER_TYPES_SUCCESS, RETRIEVE_WORKER_TYPES_FAILURE],
    endpoint: `worker_types?market_id=${marketId}`,
    schema: [workerTypeSchema],
  },
})

export const deleteProject = (projectId) => ({
  [CALL_API]: {
    types: [DELETE_PROJECT_REQUEST, DELETE_PROJECT_SUCCESS, DELETE_PROJECT_FAILURE],
    endpoint: `projects/${projectId}`,
    method: HTTP_METHODS.DELETE
  },
})